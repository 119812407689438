<!-- Order Header -->
<aix-page-header
    header="Orders"
    [breadcrumbs]="[
        {
            type: 'link',
            value: {
                routerLink: '/orders/' + storeFacade.type + '/status',
                text: 'Orders'
            }
        },
        {
            type: 'text',
            value: {
                text: (storeFacade.orderSuccess$ | async) ? (breadcrumbText | titlecase) + ' Order #' + (storeFacade.orderSuccess$ | async)?.orderNumber + '-' + (storeFacade.orderSuccess$ | async)?.title : ''
            }
        }
    ]"
>
</aix-page-header>
<main class="-no-background">
    <div class="container order">
        <div class="order-container order-container__no-padding">
            @if ((isLoading$ | async)) {
            <aix-loading [relative]="true"></aix-loading>
            }
            <aix-error-box [states]="states"></aix-error-box>
            @if ((storeFacade?.orderCancelRemoteData$ | async)! | isFailure) {
            <aix-notification
                status="error"
                [canClose]="true"
                [isOpen]="true"
                [classList]="['u-mb8']"
            >
                <strong>{{ ERROR_CANCEL_ORDER_MESSAGE }}</strong>
                <p class="u-mt8" [innerHTML]="ERROR_CONTACT | sanitizeHtml"></p>
            </aix-notification>
            }

            <div class="aix-card-sticky u-mb16 u-top40">
                <aix-page-section [title]="'Order Overview'" [dataTestingName]="'toolbar'">
                    <ng-container controls>
                        <aix-button
                            [isDisabled]="isReadOnly"
                            [buttonType]="cancelOrderButtonType"
                            [buttonLabel]="'Cancel Order'"
                            (click)="cancelOrder()"
                            [data-testing]="'cancel-order'"
                        >
                        </aix-button>
                        @if (currentStep !== 1) {
                        <aix-button
                            [isDisabled]="isReadOnly"
                            [buttonType]="editOrderButtonType"
                            [buttonLabel]="'Edit Order'"
                            (click)="onEditOrder()"
                            [data-testing]="'edit-order'"
                        >
                        </aix-button>
                        } @if (currentStep > 2) {
                        <aix-button
                            [buttonType]="viewOrderButtonType"
                            [buttonLabel]="'Preview'"
                            (click)="viewOrder()"
                            [data-testing]="'view-order'"
                        >
                        </aix-button>
                        }
                    </ng-container>
                </aix-page-section>

                @if (isReadOnly) {
                <aix-notification
                    status="alert"
                    [data-testing]="'read-only-notification'"
                    [canClose]="false"
                    [isOpen]="true"
                >
                    You have read-only access to this order. You can view but not make changes.
                </aix-notification>
                }
            </div>

            <div class="order-steps-card u-mb0">
                <aix-order-steps [order]="this.storeFacade.orderSuccess$ | async"></aix-order-steps>
            </div>
        </div>

        <div class="order-cards">
            <div class="aix-card product-details" [data-testing]="'product-details'">
                <h4>Product Details</h4>

                <div
                    class="product-details-company u-mt16"
                    *ngrxLet="this.storeFacade.orderSuccess$ as orderSuccess"
                >
                    @if ((orderSuccess.fund.logoUrl || orderSuccess.fund.fundSponsor?.logoUrl) &&
                    fundSponsorLogoNotFound === false) {
                    <img
                        [src]="orderSuccess.fund.logoUrl || orderSuccess.fund.fundSponsor?.logoUrl"
                        (error)="fundSponsorLogoNotFound = true"
                        class="product-details-logo"
                    />
                    }

                    <div class="product-details-company-title">
                        <p
                            class="aix-label aix-label--lg aix-label--grey u-mt16"
                            [data-testing]="'fund-sponsor'"
                        >
                            {{ orderSuccess.fund.fundSponsor?.name }}
                        </p>
                        <p class="product-details-company-label" [data-testing]="'fund-name'">
                            {{ orderSuccess.fund.name }}
                        </p>
                        @if (!orderSuccess.fund.ticker || orderSuccess.fund.ticker !== 'NA') {
                        <span
                            class="aix-label aix-label--md aix-label--fat aix-label--dark u-mt16"
                            style="display: block"
                            [data-testing]="'fund-ticker'"
                            >{{ orderSuccess.fund.ticker }}</span
                        >
                        }
                    </div>
                </div>

                @if ((storeFacade.orderSuccess$ | async)?.fund?.price) {
                <div class="order-card-fund-price u-mt8">
                    <span
                        class="product-details-value br-primary__color"
                        [data-testing]="'fund-price'"
                    >
                        {{ (storeFacade.orderSuccess$ | async)?.fund?.price | currency }}
                    </span>
                    <span aixFootnote [aixFootnoteContent]="footnote" [aixFootnoteNumber]="'1'">
                    </span>
                    <span
                        class="aix-label aix-label--light-grey aix-label--xm aix-label--lowercase u-ml8"
                        [data-testing]="'fund-price-descriptor'"
                    >
                        {{ (storeFacade.orderSuccess$ | async)?.fund?.priceDescriptor }}
                    </span>
                </div>
                } @if (getFundDocumentLinks(storeFacade.orderSuccess$ | async); as docLinks) {
                <div>
                    <hr class="u-mt16 u-mb0 full-width" />

                    <div class="u-mt8">
                        <span
                            class="aix-label aix-label--light-grey aix-label--lowercase aix-label--lg"
                            >Resources</span
                        >
                        <ul class="aix-card-links aix-card-links--solid">
                            <li>
                                <a
                                    class="aix-card-links-link aix-card-links-link--solid"
                                    [attr.href]="docLinks.prospectus"
                                    >Offering Documents</a
                                >
                            </li>
                            <li>
                                <a
                                    class="aix-card-links-link aix-card-links-link--solid"
                                    [attr.href]="docLinks.factSheet"
                                    >Fact Sheet</a
                                >
                            </li>
                            <li>
                                <a
                                    class="aix-card-links-link aix-card-links-link--solid"
                                    [attr.href]="docLinks.productPage"
                                    >Product Page</a
                                >
                            </li>
                        </ul>
                    </div>
                </div>
                }
            </div>

            @if (storeFacade.type === orderType.buy && (buyOrder$ | async)?.fund && (buyOrder$ |
            async)?.fund?.fundOrderType !== 'cit' && (buyOrder$ | async)?.fund?.fundOrderType !==
            '3-38') {
            <div class="aix-card product-details" [data-testing]="'order-details'">
                <h4>Order Details</h4>

                <div *ngrxLet="buyOrder$ as orderSuccess">
                    <div class="product-details-company u-mt16">
                        <p class="product-details-order-title">Purchase Amount</p>
                        <div class="product-details-order-value" [data-testing]="'purchase-amount'">
                            {{ orderSuccess.purchaseAmount | currency: 'USD':'symbol' }}
                        </div>
                    </div>

                    <div class="product-details-company u-mt16">
                        <p class="product-details-order-title">Purchase Type</p>
                        <div class="product-details-order-value" [data-testing]="'purchase-type'">
                            {{ orderSuccess.purchaseType }}
                        </div>
                    </div>

                    <div class="product-details-company u-mt16">
                        <p class="product-details-order-title">Custodial Arrangement</p>
                        <div class="product-details-order-value" [data-testing]="'holding-option'">
                            {{ orderSuccess.holdingOption?.name }}
                        </div>
                    </div>
                </div>
            </div>
            } @if ((buyOrder$ | async)?.fund && !['cit', '3-38',
            'generic-maintenance'].includes((buyOrder$ | async)?.fund?.fundOrderType)) {
            <div class="aix-card account-details" [data-testing]="'account-details'">
                <h4>Account Details</h4>

                <div class="product-details-company-title u-mt10">
                    @if ((storeFacade.orderSuccess$ | async)?.account?.custodianName) {
                    <span
                        class="aix-label aix-label--light-grey aix-label--lg"
                        [data-testing]="'custodian-name'"
                    >
                        {{ (storeFacade.orderSuccess$ | async)?.account?.custodianName }}
                    </span>
                    } @if (!(storeFacade.orderSuccess$ | async)?.account?.custodianName) {
                    <span
                        class="aix-label aix-label--light-grey aix-label--lg"
                        [data-testing]="'custodian-name'"
                    >
                        {{ (storeFacade.orderSuccess$ | async)?.holdingOption?.name }}
                    </span>
                    } @if ((storeFacade.orderSuccess$ | async)?.account?.accountNumber) {
                    <span
                        class="aix-label aix-label--light-grey aix-label--lg"
                        [data-testing]="'account-number'"
                    >
                        | No. {{ (storeFacade.orderSuccess$ | async)?.account?.accountNumber }}
                    </span>
                    }
                    <p class="product-details-company-label u-mt4" [data-testing]="'order-title'">
                        {{ (storeFacade.orderSuccess$ | async)?.title }}
                    </p>
                </div>
            </div>
            }
        </div>
    </div>

    <aix-footnotes></aix-footnotes>
</main>

<aix-modal
    #cancelOrderConfirmModal
    modalType="confirmation"
    [data-testing]="'cancelOrderModal'"
    [modalTitle]="CONFIRM_ORDER_CANCELLATION_TITLE"
    (optionSelected)="cancelOrderConfirmModalSelected($event)"
>
    <ng-template>
        <p class="u-fw500">{{ CONFIRM_ORDER_CANCELLATION_MSG }}</p>
        <p>{{ CONFIRM_ORDER_CANCELLATION_MSG_SUB }}</p>
    </ng-template>
</aix-modal>

<aix-make-changes #makeChangesConfirmModal></aix-make-changes>

<div class="product-item__container">
    <div class="product-item">
        <div class="product-item__content">
            <div class="fill-space">
                <div>
                    <div class="u-flex flex-between-center product-item__header">
                        <span>{{ productCard?.fundSponsor?.name }}</span>
                        <span class="product-item__fav">
                            @if (isLoading) {
                            <aix-loading
                                [relative]="true"
                                [style]="{ position: 'relative' }"
                                [size]="16"
                            ></aix-loading>
                            } @else {
                            <aix-flag
                                class="u-font-size--16"
                                [(value)]="productCard.favorite"
                                [onIcon]="'fas fa-star'"
                                [offIcon]="'far fa-star'"
                                (valueChange)="onClickFavorite(productCard)"
                            ></aix-flag>
                            }
                        </span>
                    </div>
                    <div class="product-item__subheader">
                        <h2 class="product-item__subheader__subtitle u-mt8 u-mb16">
                            {{ productCard?.fundName }}
                        </h2>
                    </div>
                    <div class="product-item__tags">
                        @if (!isOnboarded) {
                        <aix-tag
                            [label]="'Not Onboarded'"
                            [tooltip]="'This product is not currently set up on the system. You will be prompted to complete product forms manually.'"
                            [color]="'alert'"
                        ></aix-tag>
                        } @if (banners.length > 0) { @for (banner of banners; track banner) {
                        <aix-tag
                            [label]="banner.text"
                            [color]="banner.color"
                            [tooltip]="banner?.tooltip"
                        ></aix-tag>
                        } }
                    </div>
                </div>

                @if (productCard?.fundStructure && productCard?.fundStructure !== '') {
                <div class="product-item__footer u-mt24">
                    <div class="product-item__footer-container">
                        <span class="product-item__footer__title">{{ fundStructureLabel() }}</span>
                        <span class="product-item__footer__content u-mt8"
                            >{{ productCard?.fundStructure }}</span
                        >
                    </div>
                </div>
                }
            </div>

            <div class="u-mt16">
                @if (isMaintenance) {
                <aix-button
                    class="u-full-width"
                    (click)="onClickStart()"
                    [buttonType]="startMaintenanceButtonType"
                    [buttonLabel]="'Begin'"
                ></aix-button>
                } @else { @if (productCardOptions.length > 0) {
                <aix-product-card-begin-button
                    [data-testing]="'buy'"
                    (optionSelected)="onCardClick($event)"
                    [label]="'Begin'"
                    [options]="productCardOptions"
                ></aix-product-card-begin-button>
                } }
            </div>
        </div>
    </div>
</div>

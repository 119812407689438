@if ((actionsLoadingState$ | async)) {
<aix-loading [relative]="true" [style]="styles.loading"></aix-loading>
}

<aix-document-action-bar
    (clickDownload)="onClickDownload()"
    (clickClose)="onClickClose()"
    [downloadAll]="true"
    [isReadOnly]="isReadOnly"
></aix-document-action-bar>

<div class="aix-viewer__main-wrapper">
    <aix-error-box [states]="states"></aix-error-box>

    @if (isReadOnly) {
    <aix-notification
        class="order-process-notification"
        status="alert"
        [data-testing]="'read-only-notification'"
        [canClose]="false"
        [isOpen]="true"
    >
        You have read-only access to this order. You can view but not make changes.
    </aix-notification>
    } @if (hasComments) {
    <aix-notification
        class="order-process-notification"
        status="warning"
        [data-testing]="'read-only-notification'"
        [canClose]="true"
        [isOpen]="true"
    >
        <b>You have replied to all "Needs Clarification" comments.</b>
        @if (!needsChanges) {
        <p>Please <a class="br-link" (click)="returnOrder()">return order</a> for review.</p>
        } @if (needsChanges) {
        <p>Please <a class="br-link" (click)="editOrder()">edit order</a> and resubmit.</p>
        }
    </aix-notification>
    }

    <aix-notification
        #notification
        [status]="activeNotification.status"
        (onCloseNotification)="onCloseNotification()"
        [canClose]="canClose"
    >
        <div
            [style.fontWeight]="activeNotification.extras && activeNotification.extras.length > 0 ? 500 : 400"
        >
            {{ activeNotification.text }}
        </div>
        @if (activeNotification?.extras) {
        <div class="u-mt8">
            @for (extra of activeNotification.extras; track extra) {
            <span>
                @if (extra.type === 'text') {
                <span> {{ extra.text }} </span>
                } @if (extra.type === 'link') {
                <a (click)="store.dispatch(extra.action!)" class="br-link">{{ extra.text }}</a>
                }
            </span>
            }
        </div>
        }
    </aix-notification>

    <div class="flex-left-stretch flex-row u-full-width u-full-height">
        <div class="flex-fill u-full-width u-full-height">
            <aix-document-viewer [documentControls]="styles.documentControls"></aix-document-viewer>
        </div>

        @if ((storeFacade.orderSuccess$ | async) && !(storeFacade.documentViewer$ | async)?.offline)
        {
        <div class="aix-viewer__info-wrapper">
            <aix-document-tabs
                (onDownloadDocument)="onClickDownloadDocument($event)"
                (onNotOnboarded)="onNotOnboarded($event)"
            ></aix-document-tabs>
        </div>
        }
    </div>
</div>
